<template>
  <v-container fluid>
    <v-row style="height: 80px">
      <v-col class="pt-0">
         <v-img eager class="separator-size d-inline-flex" src="assets/separator-blue-top.svg" position="top center"/>
      </v-col>
    </v-row>
    <v-row class="text-left pa-10">
      <v-col cols="12" lg="6" offset-lg="3">
        <h1 class="turquesa--text">ESCRIBINOS</h1>
        <p class="chivo">
          Sé parte de la primera experiencia de subasta de un inmueble en la blockchain. Registra tu email para estar al tanto de las noticias y verlo online al momento que ocurra.</p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'TextoContacto'
}
</script>

<style>

</style>